import React, { useState, useEffect, useContext } from "react";
import "../styles/styles.scss";
import { ChatIcon } from "../component/image";
import Modal from 'react-modal';
import { IntercomProvider, useIntercom } from 'react-use-intercom';



// markup
const NotFoundPage = () => {
  return (
    <main>
      <IntercomProvider appId={'kq4qp3f1'} >
        <title>Not found</title>
        <ErrorModal />
      </IntercomProvider>
    </main>
  )
}

const ErrorModal = () => {
  const { boot, shutdown, hide, show, update } = useIntercom();
  React.useEffect(() => {
    boot({ alignment: 'left' });
  }, []);

  const handleLiveChatClick = (e) => {
    show()
  };
  return (
    <Modal
      isOpen={true}
      contentLabel="Welcome"
      ariaHideApp={false}>
      <div className="logo">
        <img src="https://res.cloudinary.com/b3/image/upload/co_rgb:FDBF2D,e_colorize:100,f_auto,q_auto/v1619692769/YV_Logo_YELLOWPNG-Large.png" alt="" />
      </div>
      <div className="modal-content">
        {/* <h2 className="modal-title">Page not found</h2> */}
        <div className="modal-text">Page not found. Please visit <a href="https://www.youngvic.org/" target="_blank" rel="noopener noreferrer">YoungVic.org</a> or contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a> to purchase a ticket.</div>
        <button id='live-chat' className="live-chat-link button" onClick={handleLiveChatClick}>
          <span className="icon">
            <ChatIcon />
            <span>Live Support</span>
          </span>
        </button>
      </div>
    </Modal>
  )
}

export default NotFoundPage
